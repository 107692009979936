
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Typography } from "@material-ui/core";
import { CardMedia } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Carousel from 'react-material-ui-carousel';
import CarouselSlide from 'react-material-ui-carousel';
import image1 from "../images/Filter_Coffee-min.jpg";
import image2 from "../images/Ragi_Dosa-min.jpg";
import image3 from "../images/Pudi_Dosa-min.jpg";
import image4 from "../images/Onion_Rava_Dosa-min.jpg";
import image5 from "../images/Masala_Dosa-min.jpg";
import image6 from "../images/Rava_Idly-min.jpg";

const useStyles = makeStyles((theme) => ({
  
    root: {
      flexGrow: 1,
    },
    media: {
        width:'100%',
        height:'100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'contain',
        backgroundSize: 'contain',
    },
    title : {
      color: 'red',
      fontWeight: 'bold',
    },
    name : {
      color: 'black',
      fontWeight: 590,
    },
    header: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(3),
      fontWeight: 'bold',
    },

    card: {
      position: 'relative',
      height: 510,
      width: 800,
   },
   overlay: {
      position: 'absolute',
      bottom: '0.01rem',
      color: 'black',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      backgroundColor: 'rgba(255, 255, 255, 0.54)',
   }
  
  }));

export const pictures = [
  {
      url: image1,
      title: "Filter Coffee",
      description: "Coffee afficionados must try MTR's iconic filter coffee. This special cup of filter coffee still uses the same Arabica coffee beans sourced from the same coffee plantation in Chikmagalur, Karnatake for the past 60 years.",
  },
  {
    url: image2,
    title: " Ragi Dosa",
    description: "Crepes made from millet flour, flavored with curry leaves & cumin seeds, and served with tangy chutney.",
  },
  {
    url: image3,
    title: " Pudi Masala Dosa",
    description: "Pudi Masala is what this delicious dosai is sprinkled and served with and it makes the dish more flavourful. The texture is simple and soft!",
  },
  {
    url: image4,  
    title: " Onion Rava Dosa",
    description: "Semolina crepe stuffed with spiced potatoes. Perfectly flavoured with onion, green chillies, coriander, cumin seeds, grated coconut and ghee, this makes for a light and nutritious meal to have for breakfast",
  
    },
  {
    url: image5,
    title: " Masala Dosa",
    description: "Rice & black gram crepe stuffed with spiced potatoes, laced with ghee, and served with green chutney & lentil sambar.",
  },
  {
    url: image6,
    title: " Rava Idly",
    description: "When faced with the prospect of having to remove 'rice idlis' from the menu in 1943 due to World War 2, the founder, in a stroke of inspired genius, experimented with rava and invented the Rava Idli.",
  },

]


const Speciality = () => {
  const classes = useStyles();

  return (
    <div align="center">
        <Typography variant="h3" align="center" className={classes.header}>
          <u>Our Specialities</u></Typography>
             <Carousel autoPlay interval="2500" animation="slide">
                {pictures.map((image) => (
                    <CarouselSlide key={image}>
                        <Card className={classes.card}>
                            <CardMedia
                                image={image.url}
                                // title={title}
                                className={classes.media}/>
                            <div className={classes.overlay}>
                                <Typography variant="h5" align="center" className={classes.title}>
                                      {image.title} </Typography>
                                      <Typography variant="subtitle1"  align="center" className={classes.name}>
                                      {image.description}
                                </Typography>
                            </div>
                        </Card>
                    </CarouselSlide>
                ))}
            </Carousel>
    </div>
  );
}

export default Speciality;
