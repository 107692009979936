import React from "react";

import { CardContent, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import image1 from "../images/Archive17new_filtered-min.jpg";
import image2 from "../images/Archive18_filtered-min.jpg";
import image3 from "../images/IMG_8913-min.jpg";
import image4 from "../images/IMG_8938-min.jpg";
import Card from '@material-ui/core/Card';
import { Typography } from "@material-ui/core";
import './ImageSlideShow.css';

const useStyles = makeStyles((theme) => ({

        header: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            fontWeight: 'bold',
            
        },
        media : {
          height: 300,
          width:500,
          
        },
        crd: {
          margin: theme.spacing(3),
        },
        heading: {

          color: 'red',
          
        },

}));

const MtrStory = () => {
    const classes = useStyles();
  return (
    <>
        <Typography variant="h3" id="about"
        className= {classes.header}
          align="center" ><u>The MTR Story</u></Typography>
      {/* <GlobalStyles /> */}
      <div>
        <Card className={classes.crd}>

          <CardMedia image={image1} align="left" className={classes.media} component="img"/>
                              <CardContent style={{padding:'2rem'}}>
                              <Typography variant="h5" className={classes.heading} 
                              style={{fontWeight:'bold'}} paragraph align="center">
                                    <u className="under">Humble Beginnings</u>
                                  </Typography>
                                <Typography variant="subtitle1" style={{fontWeight:'bold'}} paragraph align="center">
                                    In 1924, Parameshwara Maiya, decided to start a small
                                    restaurant on Lalbagh Fort Road in Bengaluru, India along
                                    with Ganappayya Maiya and Yagnanarayana Maiya, called
                                    the Brahmin Coffee Club serving coffee and idlis.
                                  </Typography>
                                  <Typography paragraph variant="subtitle1" style={{fontWeight:'bold'}} align="center" >
                                    In 1960, the restaurant shifted to its present location
                                    on Lalbagh Road, Bengaluru, India and grew in stature and
                                    name. MTR (Mavalli Tiffin Room) became a landmark...
                                  </Typography>
                              </CardContent>
        </Card>

        <Card className={classes.crd}>

          <CardMedia image={image2} align="right" className={classes.media} component="img"/>
          <CardContent style={{marginRight:'3rem', marginTop: '3rem'}}>
          <Typography paragraph variant="h5" align="center" className={classes.heading} >
                                <u className="under">A Defining Moment</u>
                                  </Typography>
                                <Typography paragraph variant="subtitle1" style={{fontWeight:'bold'}} align="left">
                                    In 1976, the management decided to start making and
                                    selling mixes like the Rava Idli mix and the Kharabath mix
                                    on the premises. That was the beginning of the packaged
                                    food business of MTR and from there on, there was no looking
                                    back.
                                </Typography>
                                      
                            </CardContent>
        </Card>

        <Card className={classes.crd}>

          <CardMedia image={image3} align="left" className={classes.media} component="img"/>
          <CardContent style={{marginLeft:'3rem', marginTop: '3rem'}}>
          <Typography  paragraph variant="h5" align="center" className={classes.heading}>
                                <u className="under">Snippets of History</u>
                                  </Typography>
                                <Typography paragraph variant="subtitle1"  style= {{fontWeight: 'bold'}}
                                align="center">
                                During World War 2, when rice was in short supply,
                                Yagnanarayana Maiya experimented with rava and
                                discovered the Rava Idli. It turned out to be the most
                                popular item on MTR's menu.
                                </Typography>
                                      
                            </CardContent>
        </Card>

        <Card className={classes.crd}>

          <CardMedia image={image4} align="right" className={classes.media} component="img"/>
          <CardContent>
                            <Typography variant="h5" paragraph className={classes.heading} align="center" >
                                
                                <u className="under">The Coffee Story</u>
                                  </Typography>
                                <Typography variant="subtitle1"  align="center" style= {{fontWeight: 'bold'}}>
                                Not just the idlis and dosas, but the freshly brewed filter
                                coffee too, acquired a loyal following. Its contribution to the
                                legacy of MTR is immense. Whether it was exchanges
                                between freedom fighters or businessmen brokering deals,
                                the coffee at MTR has acted as the catalyst. Perhaps his
                                passion for the brew made Yagnappa go to the extent he did
                                to get it right. He meticulously chose the beans,
                                roasting and grinding them daily, so that there was no loss
                                of flavour. Buffalo milk was used to enhance the flavour of
                                the decoction when mixed. To make the experience
                                enduring, silver cups were used to serve the coffee topped
                                with precisely 1/4 of an inch of froth.
                                </Typography>
                                      
                            </CardContent>
        </Card>
      </div>
    </>
  );
};

export default MtrStory;
