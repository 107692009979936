import React from 'react';

import { Switch, Route } from 'react-router-dom';

import ContactUs from './contactUs/ContactUs';
import Home from './Home/Home';
import Order from './Order/Order';
import MtrStory from './mtrstory/MtrStory';


const Main = () => {
    return (
        
        <Switch>
            <Route exact path='/' component={Home}></Route>
            {/* <Route exact path='/menu' component={Menu}></Route> */}
            <Route exact path='/about' component={MtrStory}></Route>
            <Route exact path='/order' component={Order}></Route>
            <Route exact path='/contact' component={ContactUs}></Route>
        </Switch>
    );
}

export default Main;
