import React, {lazy, Suspense} from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Main from './components/Main';
// const Main = lazy(()=> import ('./components/Main'));


export default function App() {
  
  return (
    // <Suspense fallback={<div>Loading.......</div>}>
      <div>
        <Navbar />
        <Main style={{paddingTop: "50%"}}/>
        <Footer/> 
      </div>
    // </Suspense>
  );
}
