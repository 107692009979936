import React from 'react';
import ImageSlideShow from './ImageSlideShow';
import ContactUs from '../contactUs/ContactUs';
import Timings from './Timings';
import MtrStory from '../mtrstory/MtrStory';
import Speciality from '../specialities/Speciality';
import Order from '../Order/Order';
import Media from '../Media/Media';


const Home = () => {

    return(
        <div id ="home" style={{paddingTop:'8%'}}>
            <ImageSlideShow/>
            
            <MtrStory/>
            <Speciality />
            <Timings/>
            <Order/>
            <Media/>
            <ContactUs/>
        </div>
    );

}

export default Home;
