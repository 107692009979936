import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import "./contactus.css";
import callIcon from './call_black_48dp.svg'

import locIcon from './near_me_black_48dp.svg'
import mailIcon from './mail_black_48dp.svg'


const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

      display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
      
    },
    
    title: {
      flexGrow: 1,
      fontStyle: 'bold',
      fontFamily: 'Montserrat',
    },

    name: {
      fontFamily: 'Montserrat',
    },

    space: {

       marginLeft: theme.spacing(2),
       marginRight: theme.spacing(2),

    },

    gridBack: {
      margin: theme.spacing(3),
    },
    
  }));

  // const iconMarker = new window.google.maps.MarkerImage(
  //   {locIcon},
  //   null, /* size is determined at runtime */
  //   null, /* origin is 0,0 */
  //   null, /* anchor is bottom center of the scaled image */
  //   new window.google.maps.Size(32, 32)
  // );

  const GoogleMapExample = withGoogleMap(props => (
    <GoogleMap
      defaultCenter = { { lat: 3.1316721,lng: 101.6850335 } }
      defaultZoom = { 18 }>

      <Marker icon={{
          url : {locIcon},
        scaledSize: new window.google.maps.Size(150, 50)
        }}
        position={{ lat: 3.1316721,lng: 101.6850335 }}/>

    </GoogleMap>
  ));

export default function ContactUs() {

  const classes = useStyles();

  function ContactDetails() {
    return (
      <div align="center" style={{marginTop:'2rem'}}>

            <Typography variant="h4" style={{fontWeight:'bold'}} >
              <u >Contact Us</u>
            </Typography>
        <ul style={{marginTop:'2rem'}}>

        <li>

          <img src ={callIcon} align="center" 
                      width="30"
                      height="30"
                      alt="Call MTR"/>
        </li>
        <li>
        <Typography variant="subtitle1" 
                            className="nav-links" style={{fontWeight: '580'}}>
                            +60 322 764924 / +60 112 6472273
                            </Typography>
        </li>
      </ul>

        <ul >
          <li>

          <img src ={locIcon} align="center" 
                      width="30"
                      height="30"
                      alt="Directions to MTR"/>

          </li>
          <li>
          <Typography variant="subtitle1" 
                              className="nav-links" style={{fontWeight: '580'}}>
                              No.69, Jalan Thambipillay, 
                              Brickfields Kuala Lumpur 50470, Malaysia.
                              </Typography>
          </li>
          </ul>

          <ul>
                  <li>
                  <img src ={mailIcon} align="center"
                              width="30"
                              height="30"
                              alt="Mail MTR"/>

                  </li>
                  <li>
                  <a href="mailto: contact@culinventures.com">
                    <Typography variant="subtitle1" 
                                      className="nav-links" style={{ fontWeight: '580'}}>
                                      contact@culinventures.com
                                      </Typography></a>
                  </li>
                </ul>
      </div>

    )
  }

  return(

    <div className={classes.root} id="contact">
          {/* <div className="contactdet" > */}

            <Grid container spacing={1} className={classes.gridBack}>
                        <Grid item>
                          <ContactDetails/>
                        </Grid>
                        <Grid item style={{marginLeft: '2rem'}}>
                        <GoogleMapExample
                            containerElement={ <div className="mapp" style={{ height: `400px`, width: '600px' }} /> }
                            mapElement={ <div style={{ height: `100%` }} /> }>
                        </GoogleMapExample>
                        </Grid>
            </Grid>
    </div>

    

);
}

