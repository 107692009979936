import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const THEME = createMuiTheme({
  typography: {
    "fontFamily": "Montserrat",
    "lineHeight": 1,
    "letterSpacing": 0.32,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h5: {
      "fontWeight": 700,
    },
    h6: {
      "fontWeight": 600,

    },
  },
});

const useStyles = makeStyles((theme)=> ({

  root: {
    
  },
  
  title: {
    flexGrow: 1,
    fontStyle: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontFamily: 'Montserrat',
  },
  
}));

export default function Timings() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
         <Typography variant="h3" className={classes.title}
            align="center" style={{ marginTop: "6rem", fontWeight:'bold' }}>
                <u >Business Operations</u>
          </Typography>

        <MuiThemeProvider theme={THEME}>
          <Typography variant="h5" className={classes.title}
           color="inherit" align="center" style={{ margin: "1rem" }}>
                Weekday Timings
          </Typography>
        </MuiThemeProvider>
        
        <Typography variant="subtitle1" 
        className={classes.title} align="center" style={{fontWeight: '450'}}>
          8:00 AM to 3:30 PM & 5:00 PM to 10:30 PM

          </Typography>

              <MuiThemeProvider theme={THEME}>
                <Typography variant="h5" className={classes.title}
                color="inherit" align="center" >
                      Weekend Timings <Typography variant="overline"
                color="inherit" style={{fontWeight: '700'}}> (Sat & Sun) </Typography>
                </Typography>
              </MuiThemeProvider>

              <Typography variant="subtitle1" 
        className={classes.title} align="center" style={{fontWeight: '450'}}>
              8:00 AM to 10:30 PM

              </Typography>

            <MuiThemeProvider theme={THEME}>
              <Typography variant="h5" className={classes.title}
              color="inherit" align="center" style={{ margin: "1rem" }}>
                    MENU 
              </Typography>
            </MuiThemeProvider>

            <Typography variant="subtitle1" 
        className={classes.title} align="center" style={{fontWeight: '450'}}>
              8:00 am – 11:30 pm - Breakfast
            </Typography>

              <Typography variant="subtitle1" 
        className={classes.title} align="center" style={{fontWeight: '450'}}>
              11:30 pm – 3:30 pm - Lunch
        </Typography>

        <Typography variant="subtitle1" 
        className={classes.title} align="center" style={{fontWeight: '450'}}>
              5:00 pm – 7:30 pm - Snacks
        </Typography>
      
        <Typography variant="subtitle1" 
        className={classes.title} align="center" style={{fontWeight: '450', marginBottom: '5rem'}}>
              7:30 pm – 10:30 pm - Dinner
        </Typography>

    </div>
  );
}
