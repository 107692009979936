import React from "react";
import {pictures} from '../specialities/Speciality';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import './order.css';
import Card from '@material-ui/core/Card';
import { Typography } from "@material-ui/core";
import Carousel from 'react-material-ui-carousel';
import CarouselSlide from 'react-material-ui-carousel';
import grabIcon from '../images/logos/grab.svg';
import airAsiaIcon from '../images/logos/airasiacom_logo.svg';


const useStyles = makeStyles((theme) => ({

        header: {
            margin: theme.spacing(8),
            fontWeight: 'bold',
            
        },
        media : {
          height: 500,
          width:650,
          
        },
        crd: {
          margin: theme.spacing(3),
        },
        
        heading: {

        //   color: 'red',
          
        },

        root: {
            display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          overflow: 'hidden',
            
          },
          
          title: {
            flexGrow: 1,
            fontStyle: 'bold',
            fontFamily: 'Montserrat',
          },
      
          name: {
            fontFamily: 'Montserrat',
          },
      
          space: {
      
             marginLeft: theme.spacing(2),
             marginRight: theme.spacing(2),
      
          },
      
          gridBack: {
            margin: theme.spacing(3),
          },

}));

const Order = () => {
    const classes = useStyles();

    function Ordercard() {
        return (
            <div  align="center" style={{marginTop: '15%'}}>
            <Typography paragraph variant="h4"  style={{fontWeight:'bold'}} 
                className={classes.heading} >
                        <u > Order Now</u>
                                    </Typography>
                                    <Typography paragraph variant="subtitle1" 
                                    style={{fontWeight:'bold', padding: '2rem'}}>

                MTR allows you to experience delicious MTR’s food from the comfort of your sofa at home or cubicle in office. All orders are delivered quickly and efficiently, allowing you the peace of mind to know that smiles-inducing food is never too far
                    </Typography>

                    <Typography paragraph variant="subtitle1" 
                                    style={{fontWeight:'bold', color: 'red'}}>

                        Now Available On: 
                    </Typography>

                    <img src={grabIcon} height="120px" width="120px" alt="Grab Icon" style={{marginRight: "1.5rem"}}/>
                    <img src={airAsiaIcon} height="120px" width="120px" alt="Air Asia Icon" style={{marginLeft: "1.5rem"}}/>
                    
            </div>
    
        )
      }

      function OrderCaro() {
          return (
            <div >
            <Carousel autoPlay interval="2500" animation="slide">
              {pictures.map((image) => (
                  <CarouselSlide key={image}>
                      <Card className={classes.card}>
                          <CardMedia
                              image={image.url}
                              // title={title}
                              className={classes.media}/>
                      </Card>
                  </CarouselSlide>
              ))}
          </Carousel>
        </div>
          )
      }

      
  return (
      <div id="order" className={classes.root}>

            <Grid container spacing={3}  >
                        <Grid item xs>
                            <OrderCaro/>
                        </Grid>
                        <Grid item xs> 
                        <Ordercard/>
                        </Grid>
            </Grid>
      </div>
  );
};

export default Order;
