import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import './Footer.css';

import { grey } from '@material-ui/core/colors';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Montserrat',
    color : "black",
    textAlign: 'start',
    fontWeight: '580',
    
  },
  social: {
    textAlign: 'end',
    marginRight : '1rem',
    marginTop : '0.1rem',
    fontWeight: '600',
  },
  
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Typography variant="subtitle2"
                  className={classes.title}>
                   © {(new Date().getFullYear())} Copyright By - MTR
      </Typography>

      <Typography variant="subtitle2"
                  className={classes.social}>
                   Follow Us 
      </Typography>

      <ui className="nav-social">
            <li>
              <a href="https://www.facebook.com/mtr1924malaysia/" rel="noreferrer" target="_blank"><FacebookIcon 
              style={{ color:  grey[900] }}
               /></a>
            </li>
            <li>
              <a href="https://www.instagram.com/mtr1924malaysia/?hl=en" rel="noreferrer" target="_blank"><InstagramIcon 
              style={{ color:  grey[900] }}/></a>
            </li>
            <li>
              <a href="https://twitter.com/mtr1924malaysia?lang=en" rel="noreferrer" target="_blank"><TwitterIcon 
              style={{ color: grey[900] }} /></a>
            </li>
          </ui>


    </div>
  );
}
