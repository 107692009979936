import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';
import image1 from "../images/MTR_Banner.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(5),
  },
  media: {
      width:'100%',
      height:510,
      backgroundRepeat: 'no-repeat',
      
      backgroundPosition: 'contain',
      backgroundSize: 'contain',
  },
  name : {
    color: 'black',
    fontWeight: 'bold',
  },
  header: {
    margin: theme.spacing(5),
    fontWeight: 'bold',
  },
  sizing: {
       height: 510,
      
      //  margin: 'auto',
      // width: 300,
  }
  
}));

// const pictures = [
//   {
//       url: image1
//   },
//   {
//       url: image2
//   },
//   {
//     url: image3
//   },
//   {
//     url: image4
//   },
// ]


const ImageSlideShow = () => {

  const classes = useStyles();

  return (
      <div>
        <CardMedia
          className={classes.media}
          image={image1}
          title=""/>
      </div>
  );
};

export default ImageSlideShow;
