import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Typography, List, ListItem,
  withStyles, Grid, SwipeableDrawer
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import logo from './MTR_Logo.svg';
import {HashLink as Link} from "react-router-hash-link";
import './Navbar.css';

import { grey } from '@material-ui/core/colors';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const styleSheet = {
  list : {
    width : 200,
  },

  social: {
    textAlign: 'end',
    marginRight : '1rem',
    marginTop : '0.2rem',
    fontWeight: '580',
  },
  padding : {
     //paddingRight : 10,
    color: 'black',
    cursor : "pointer",
  },

  title: {
    
    justifyContent: 'center',
    color: 'black',
  },

  sideBarIcon : {
    padding : 0,
    color : "black",
    cursor : "pointer",
  }
}

class Navbar extends Component{
  constructor(props){
    super(props);
    this.state = {drawerActivate:false, drawer:false};
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  componentWillMount(){
    if(window.innerWidth <= 600){
      this.setState({drawerActivate:true});
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 600){
        this.setState({drawerActivate:true});
      }
      else{
        this.setState({drawerActivate:false})
      }
    });
  }

  //Small Screens
  createDrawer(){
    return (
      <div>
        <AppBar position ="absolute" style={{ background: 'white', boxShadow: 'none'}} >
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <MenuIcon
                className = {this.props.classes.sideBarIcon}
                onClick={()=>{this.setState({drawer:true})}} />

                <Link smooth align= "center" className= {this.props.classes.title} to="#home"
                      // smooth={true} duration={500} spy={true} exact='true' 
                      // offset={-80}
                      ><img  src ={logo}
                      width="120px"
                      align="center"
                      height="120px"
                      alt="MTR"/></Link>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
         open={this.state.drawer}
         onClose={()=>{this.setState({drawer:false})}}
         onOpen={()=>{this.setState({drawer:true})}}>

           <div
             tabIndex={0}
             role="button"
             onClick={()=>{this.setState({drawer:false})}}
             onKeyDown={()=>{this.setState({drawer:false})}}>

            <List className = {this.props.classes.list}>
               <ListItem key = {1} button divider>
               <Typography  variant="subtitle1">

               <Link smooth className= {this.props.classes.title} to="#home"
                      
                      >Home</Link>

              </Typography>
               </ListItem>
               <ListItem key = {2} button divider>
                 
               <Typography  variant="subtitle1">
               <Link smooth className= {this.props.classes.title} to="#menu"
                      // smooth={true} duration={500} spy={true} exact='true' 
                      // offset={-80}
                      >Menu</Link>
                </Typography>
               </ListItem>
               <ListItem key = {3} button divider>

                 <Typography  variant="subtitle1">

                   
                 <Link smooth className= {this.props.classes.title} to="#about"
                      
                      >About</Link>
                  </Typography>

               </ListItem>
               <ListItem key = {4} button divider>

                 <Typography  variant="subtitle1">

                   
                 <Link smooth className= {this.props.classes.title} to="#order"
                      
                      >Order Now</Link>
                  </Typography>

                  
               </ListItem>

               <ListItem key = {5} button divider>

                 <Typography  variant="subtitle1">

                 <Link smooth className= {this.props.classes.title} to="#contact"
                      
                      >Contact Us</Link>
                  </Typography>

               </ListItem>

               <ListItem key={6} button divider>
               <Typography variant = "subtitle1">
                    
                  <Link smooth className= {this.props.classes.title} to="#media"
                      
                      >Media</Link>
                  </Typography>
               </ListItem>

               <ListItem key={7} divider>
               <Typography variant="subtitle1"
                  >
                   Follow Us On 
                </Typography>
               </ListItem>

               <ListItem key = {8} button divider>

               <ui className="nav-social" align="center">
                  <li>
                    <a href="https://www.facebook.com/mtr1924malaysia/" rel="noreferrer" target="_blank"><FacebookIcon 
                    style={{ color:  grey[900] }}
                    /></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mtr1924malaysia/?hl=en" rel="noreferrer" target="_blank"><InstagramIcon 
                    style={{ color:  grey[900] }}/></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/mtr1924malaysia?lang=en" rel="noreferrer" target="_blank"><TwitterIcon 
                    style={{ color: grey[900] }} /></a>
                  </li>
               </ui>
                
                </ListItem>

                
             </List>

         </div>
       </SwipeableDrawer>

      </div>
    );
  }

  //Larger Screens
  destroyDrawer(){
    const {classes} = this.props
    
    return (
      <AppBar position= "absolute" style={{ background: 'white', boxShadow: 'none'}}> 
        <Toolbar>

                  <Link smooth to="#home"
                      
                      ><img  src ={logo}
                      width="120px"
                      height="120px"
                      alt="MTR"/></Link>

                  <div align="center" className="nav-menu">
                  {/* <Typography variant = "h6" className = {classes.padding} color="inherit" >
                    
                  <Link smooth className= {classes.title} to="#home"
                      
                      >Home</Link>
                  </Typography> */}
                  <Typography variant = "h6" className = {classes.padding} color="inherit" >

                  <Link smooth className= {classes.title} to="#menu"
                      
                      >Menu</Link>
                  </Typography>
                  <Typography variant = "h6" className = {classes.padding} color="inherit" >
                    
                  <Link smooth className= {classes.title} to="#about"
                      // smooth={true} duration={500} spy={true} exact='true' 
                      // offset={-80}
                      >About</Link>

                  </Typography>
                  <Typography variant = "h6" className = {classes.padding} color="inherit" >
                    
                  <Link smooth className= {classes.title} to="#order"
                      // smooth={true} duration={500} spy={true} exact='true' 
                      // offset={-80}
                      >Order now</Link>
                  </Typography>
                  <Typography variant = "h6" className = {classes.padding} color="inherit" >
                    
                  <Link smooth className= {classes.title} to="#contact"
                      // smooth={true} duration={500} spy={true} exact='true' 
                      // offset={-80}
                      >Contact Us</Link>
                  </Typography>

                  <Typography variant = "h6" className = {classes.padding} color="inherit" >
                    
                  <Link smooth className= {classes.title} to="#media"
                      // smooth={true} duration={500} spy={true} exact='true' 
                      // offset={-80}
                      >Media</Link>
                  </Typography>

                  </div>
          </Toolbar>
      </AppBar>
    )
  }

  render(){
    return(
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

Navbar.propTypes = {
  classes : PropTypes.object.isRequired
};



export default withStyles(styleSheet)(Navbar);
