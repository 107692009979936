import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {CardMedia} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Carousel from 'react-material-ui-carousel';
import CardActionArea from "@material-ui/core/CardActionArea";
import CarouselSlide from 'react-material-ui-carousel';
import image1 from '../images/logos/FollowMeToEat.jpg';
import image2 from '../images/logos/food_malaysia.jpg';
import image3 from '../images/logos/malay_mail.jpg';
import image4 from '../images/logos/malaysian_foodie.jpg';
import image5 from '../images/logos/newTsol_logo.png';
import image6 from '../images/logos/The_food_bunny.jpg';
import image7 from '../images/logos/The-Yum-List.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 350,
    width: 500,
  },
  card: {
    position: 'relative',
    height: 350,
    width: 500,
 },
  media: {
    width:'70%',
    height:'70%',
    paddingTop: '56%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'contain',
    backgroundSize: 'contain',
},
  control: {
    padding: theme.spacing(2),
  },
}));

const mediaItems= [
    {
        text: "India’s famous MTR 1924 restaurant is now in Malaysia!",
        media: image5,
        link: 'https://www.thestar.com.my/lifestyle/food/2018/06/12/indias-famous-mtr-1924-restaurant-is-now-in-malaysia',

        
    },
    {
        text: "Bangalore’s MTR 1924 has landed in KL... with a bang!",
        media: image3,
        link: 'https://www.malaymail.com/news/eat-drink/2018/03/18/bangalores-mtr-1924-has-landed-in-kl...-with-a-bang/1600159',

    },
    {
        text: "MTR, Indian Vegetarian Restaurant, Brickfields",
        media: image7,
        link: 'https://www.theyumlist.net/2018/07/mtr-indian-vegetarian-brickfields.html',
    },
    {
        text: "MTR 1924 ~ Best South Indian Vegetarian Food At Brickfields Kuala Lumpur",
        media: image1,
        link: "https://followmetoeatla.blogspot.com/2018/10/mtr-1924-best-south-indian-vegetarian-food-brickfields-kuala-lumpur-bangalore-india.html",

    },
    {
        text: "MTR 1924 @Brickfields : Truly Southern Indian Cuisine",
        media: image4,
        link : "https://www.malaysianfoodie.com/2018/01/mtr-1924-brickfields-truly-southern-indian-cuisine.html#.YNsyWegzbIU",

    },
    {
        text: "AUTHENTIC SOUTH INDIAN VEGETARIAN CUISINE @ MTR 1924, BRICKFIELDS",
        media: image2,
    },
    {
        text: "MTR 1924 @ Brickfields – Oooo man, their thosai … soooo good!",
        media: image6,
        link : "https://thefoodbunny.com/2018/04/19/mtr-1924-brickfields-oooo-man-their-thosai-soooo-good/",
    },
]

export default function Media() {
  
  const classes = useStyles();


  return (
      <div id= "media" className={classes.root} align="center">
            <Typography align="center" variant="h3" style={{fontWeight: 'bold', margin: '3rem'}}>
                <u>Media</u>
            </Typography>

            <Carousel autoPlay interval="2500" animation="slide">
                {mediaItems.map((item) => (
                    <CarouselSlide key={item}>
                        <Card className={classes.card} style={{boxShadow: '1' }}>
                            <CardActionArea
                                component="a"
                                target="_blank"
                                href={item.link}
                                onClick={() => console.log("CardActionArea clicked")}>

                                <Typography variant="h5" align="center" style={{marginTop: '4rem'}}
                                className={classes.title}>
                                        {item.text} </Typography>
                                <CardMedia
                                    image={item.media}
                                    // title={title}
                                    className={classes.media}/>
                                    
                                </CardActionArea>
                        </Card>
                    </CarouselSlide>
                ))}
            </Carousel>
                
      </div>
    
  );
}
